<template>
    <div class="v-inspection">
        <div class="spinner-wrap" v-show="!dataIsLoaded">
            <Spinner />
        </div>
        <p class="none-questions-text" v-show="dataIsLoaded && isNoneQuestions">
            There are no questions in this inspection yet.
        </p>
        <div v-show="dataIsLoaded && !isNoneQuestions">
            <div class="head">
                <div class="top">
                    <h2>Exterior Inspection</h2>
                    <div class="right">
                        <div class="prev-page page-btn" @click="prevPage" v-if="$route.params.page!=1">
                            <img src="@/assets/arrow-left.svg" alt="arrow">
                        </div>
                        <span>{{ $route.params.page }} of {{ pageAmount }}</span>
                        <div class="next-page page-btn" @click="nextPage" v-if="$route.params.page!=pageAmount">
                            <img src="@/assets/arrow-left.svg" alt="arrow">
                        </div>
                    </div>
                </div>
                <div class="progress-bar" ref="progressBar">
                    <div class="progress" :style="`width: ${progressWidth}px;`"></div>
                </div>
            </div>
            <div class="inspection-container">
                <div
                    class="general-questions-wrap questions-wrap"
                    :style="`min-height: 64px;height: ${isOpen.general ? 'auto' : questionsHeadHeight + 'px'};`"
                    v-if="generalQuestions.length > 0"
                >
                    <div 
                        class="question-type-wrap"
                        @click="toggleQuestions('general')"
                        ref="questions-head"
                    >
                        <h2 class="question-type">General</h2>
                        <div class="arrow">
                            <img
                                src="@/assets/arrow-left.svg"
                                alt="arrow"
                                :class="`arrow ${isOpen.general ? 'open' : 'close'}`"
                            >
                        </div>
                    </div>
                    <Question
                        v-for="item in generalQuestions"
                        :key="item.id"
                        :id="item.id"
                        :index="item.index"
                        :question="item.question"
                        :answer="item.answer"
                        :order="item.order"
                        :buttonClick="setAnswer"
                        :propImages="item.images"
                        :noteHandler="noteHandler"
                        :propNote="item.note"
                    />
                </div>
                <div
                    class="property-questions-wrap questions-wrap"
                    :style="`min-height: 64px;height: ${isOpen.property ? 'auto' : questionsHeadHeight + 'px'}`"
                    v-if="propertyQuestions.length > 0"
                >
                    <div 
                        class="question-type-wrap" 
                        @click="toggleQuestions('property')"
                    >
                        <h2 class="question-type">Property</h2>
                        <div class="arrow">
                            <img
                                src="@/assets/arrow-left.svg"
                                alt="arrow"
                                :class="`arrow ${isOpen.property ? 'open' : 'close'}`"
                            >
                        </div>
                    </div>
                    <Question
                        v-for="item in propertyQuestions"
                        :key="item.id"
                        :id="item.id"
                        :index="item.index"
                        :question="item.question"
                        :answer="item.answer"
                        :order="item.order"
                        :buttonClick="setAnswer"
                        :propImages="item.images"
                        :noteHandler="noteHandler"
                        :propNote="item.note"
                    />
                </div>
                <div
                    class="exterior-questions-wrap questions-wrap"
                    :style="`min-height: 64px;height: ${isOpen.exterior ? 'auto' : questionsHeadHeight + 'px'}`"
                    v-if="exteriorQuestions.length > 0"
                >
                    <div 
                        class="question-type-wrap" 
                        @click="toggleQuestions('exterior')"
                    >
                        <h2 class="question-type">Exterior</h2>
                        <div class="arrow">
                            <img
                                src="@/assets/arrow-left.svg"
                                alt="arrow"
                                :class="`arrow ${isOpen.exterior ? 'open' : 'close'}`"
                            >
                        </div>
                    </div>
                    <Question
                        v-for="item in exteriorQuestions"
                        :key="item.id"
                        :id="item.id"
                        :index="item.index"
                        :question="item.question"
                        :answer="item.answer"
                        :order="item.order"
                        :buttonClick="setAnswer"
                        :propImages="item.images"
                        :noteHandler="noteHandler"
                        :propNote="item.note"
                    />
                </div>
                <div
                    class="interior-questions-wrap questions-wrap"
                    :style="`min-height: 64px;height: ${isOpen.interior ? 'auto' : questionsHeadHeight + 'px'}`"
                    v-if="interiorQuestions.length > 0"
                >
                    <div 
                        class="question-type-wrap" 
                        v-show="interiorQuestions.length > 0"
                        @click="toggleQuestions('interior')"
                    >
                        <h2 class="question-type">Interior</h2>
                        <div class="arrow">
                            <img
                                src="@/assets/arrow-left.svg"
                                alt="arrow"
                                :class="`arrow ${isOpen.interior ? 'open' : 'close'}`"
                            >
                        </div>
                    </div>
                    <Question
                        v-for="item in interiorQuestions"
                        :key="item.id"
                        :id="item.id"
                        :index="item.index"
                        :question="item.question"
                        :propNote="item.note"
                        :answer="item.answer"
                        :order="item.order"
                        :buttonClick="setAnswer"
                        :propImages="item.images"
                        :noteHandler="noteHandler"
                    />
                </div>
                <div
                    class="interior-questions-wrap questions-wrap"
                    :style="`min-height: 64px;height: ${isOpen.cemetery ? 'auto' : questionsHeadHeight + 'px'}`"
                    v-if="cemeteryQuestions.length > 0"
                >
                    <div 
                        class="question-type-wrap" 
                        v-show="cemeteryQuestions.length > 0"
                        @click="toggleQuestions('interior')"
                    >
                        <h2 class="question-type">Cemetery</h2>
                        <div class="arrow">
                            <img
                                src="@/assets/arrow-left.svg"
                                alt="arrow"
                                :class="`arrow ${isOpen.cemetery ? 'open' : 'close'}`"
                            >
                        </div>
                    </div>
                    <Question
                        v-for="item in cemeteryQuestions"
                        :key="item.id"
                        :id="item.id"
                        :index="item.index"
                        :question="item.question"
                        :propNote="item.note"
                        :answer="item.answer"
                        :order="item.order"
                        :buttonClick="setAnswer"
                        :propImages="item.images"
                        :noteHandler="noteHandler"
                    />
                </div>
                <div
                    class="home-questions-wrap questions-wrap"
                    :style="`min-height: 64px;height: ${isOpen.cemetery ? 'auto' : questionsHeadHeight + 'px'}`"
                    v-if="homeQuestions.length > 0"
                >
                    <div 
                        class="question-type-wrap" 
                        v-show="cemeteryQuestions.length > 0"
                        @click="toggleQuestions('interior')"
                    >
                        <h2 class="question-type">Housing Community Inspection</h2>
                        <div class="arrow">
                            <img
                                src="@/assets/arrow-left.svg"
                                alt="arrow"
                                :class="`arrow ${isOpen.cemetery ? 'open' : 'close'}`"
                            >
                        </div>
                    </div>
                    <Question
                        v-for="item in homeQuestions"
                        :key="item.id"
                        :id="item.id"
                        :index="item.index"
                        :question="item.question"
                        :propNote="item.note"
                        :answer="item.answer"
                        :order="item.order"
                        :buttonClick="setAnswer"
                        :propImages="item.images"
                        :noteHandler="noteHandler"
                    />
                </div>
            </div>
            <div class="btn-wrap">
                <div class="back-top-btn" @click="backToTop">
                    <img src="@/assets/arrow-up-circle.svg" alt="arrow-top">
                </div>
            </div>
            <div class="btn-wrap">
                <Button
                    text="save"
                    classBtn="save-btn"
                    :isActive="true"
                    :click="saveAllQuestions"
                    :isShowSpinner="isShowBtnSpinner"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { nanoid } from 'nanoid'
import Question from "./components/Question"
import InspectionsHistory from "./components/InspectionsHistory"
import { getInspection } from "../../api/inspections"
import Button from "../../components/Button"
import { setAnswer, saveNotes } from "../../api/inspections"
import Spinner from "../../components/Spinner"
import BtnSpinner from "../../components/BtnSpinner"

export default {
    data: function() {
        return {
            dataIsLoaded: false,
            questions: [],
            progressBarWidth: 0,
            isOpen: {
                general: true,
                property: true,
                exterior: true,
                interior: true,
                cemetery: true,
            },
            isShowBtnSpinner: false,
            questionsHeadHeight: 0
        }
    },
    components: {
        Question,
        InspectionsHistory,
        Button,
        Spinner,
        BtnSpinner,
    },
    async mounted() {
        this.progressBarWidth = this.$refs["progressBar"].clientWidth

        try {
            let { inspectionId } = this.$route.params
            let response = await getInspection(inspectionId)
            let alphabet = " abcdefghijklmnopqrstuvwxyz".split("")

            response.questions.forEach(item => {
                let order = String(item.order)
                if(order.includes(".")) {
                    let arr = order.split(".")
                    item.order = arr[0] + alphabet[arr[1]]
                }
            })

            this.questions = response.questions

            this.$store.commit("header/setData", {
                title: response.type,
                description: response.address,
                showPrevBtn: true,
                showSaveBtn: true,
                showHistoryBtn: true,
                prevLink: "/regular-inspections",
                saveBtnState: "active",
                saveBtnHandler: this.saveAllQuestions,
            })

            this.dataIsLoaded = true
        } catch (err) {
            console.error(err)
        }

    },
    computed: {
        pageAmount() {
            return Math.ceil(this.questions.length / 20)
        },
        filteredQuestions() {
            let { page } = this.$route.params
            let start = page == 1 ? 0 : (page - 1) * 20
            let finish = start + 20

            return this.questions.slice(start, finish)
        },
        generalQuestions() {
            return this.filteredQuestions.filter(item => item.type === "general information")
        },
        propertyQuestions() {
            return this.filteredQuestions.filter(item => item.type === "property inspection")
        },
        exteriorQuestions() {
            return this.filteredQuestions.filter(item => item.type === "exterior inspection")
        },
        interiorQuestions() {
            return this.filteredQuestions.filter(item => item.type === "interior inspection")
        },
        cemeteryQuestions() {
            return this.filteredQuestions.filter(item => item.type === "cemetery")
        },
        homeQuestions() {
            return this.filteredQuestions.filter(item => item.type === "housing community inspection")
        },
        progressWidth() {
            return this.progressBarWidth / this.pageAmount * this.$route.params.page
        },
        isNoneQuestions() {
            return this.questions.length === 0
        },
        isActiveStateBtn() {
            let isActive = true

            this.questions.forEach((question, id) => {
                if(!question.answer)
                    isActive = false
            })

            return isActive
        }
    },
    methods: {
        noteHandler(e, id) {
            let index = this.questions.findIndex(item => item.id === id)
            
            this.questions[index].note = e.currentTarget.value
        },
        async saveAllQuestions() {
            try {
                this.isShowBtnSpinner = true
                this.$store.commit("header/setData", {
                    title: "Initial Property Inspection Report",
                    description: "6358 Lone Wolf Trail undefined Gresham, California 54159",
                    showPrevBtn: true,
                    showSaveBtn: true,
                    showHistoryBtn: true,
                    prevLink: "/regular-inspections",
                    saveBtnState: "active",
                    saveBtnHandler: this.saveAllQuestions,
                    showSaveBtnSpinner: true,
                })
                let { inspectionId } = this.$route.params
                let questionsWithNotes = this.questions.filter(item => item.note)

                let response = await saveNotes(inspectionId, { questions: questionsWithNotes })

                this.isShowBtnSpinner = false
                this.$store.commit("header/setData", {
                    title: "Initial Property Inspection Report",
                    description: "6358 Lone Wolf Trail undefined Gresham, California 54159",
                    showPrevBtn: true,
                    showSaveBtn: true,
                    showHistoryBtn: true,
                    prevLink: "/regular-inspections",
                    saveBtnState: "active",
                    saveBtnHandler: this.saveAllQuestions,
                    showSaveBtnSpinner: false,
                })
            } catch (err) {
                console.error(err)
            }
        },
        async setAnswer(id, text) {
            let index = this.questions.findIndex(item => item.id === id)

            try {
                let response = await setAnswer(id, { value: text, inspectionId: this.$route.params.inspectionId })

                this.questions[index].answer = text
            } catch (err) {
                console.error(err)
            }
        },
        nextPage() {
            let { inspectionId, page } = this.$route.params
            this.$router.push(`/inspection/${inspectionId}/${+page+1}`)
        },
        prevPage() {
            let { inspectionId, page } = this.$route.params
            this.$router.push(`/inspection/${inspectionId}/${+page-1}`)
        },
        backToTop() {
            window.scroll({
                top: 0,
                behavior: 'smooth'
            })
        },
        toggleQuestions(type) {
            this.isOpen[type] = !this.isOpen[type]
        },
    },
}
</script>

<style lang="sass">
@import ../../helpers/mixins
    
div.v-inspection
    padding: 0 15px 30px 15px

    div.head
        max-width: 1030px
        margin: 0 auto

        h2
            color: #124C7B
            font-weight: 400
            font-size: 20px
            line-height: 28px
            letter-spacing: 0.02em

        div.right
            display: flex
            align-items: center

        span
            display: inline-block
            padding: 0 12px

        div.page-btn
            width: 44px
            height: 44px
            background: linear-gradient(0deg, rgba(126, 211, 212, 0.6), rgba(126, 211, 212, 0.6)), #FFFFFF
            display: flex
            justify-content: center
            align-items: center
            padding: 5px
            cursor: pointer

            &.next-page
                img
                    transform: rotate(180deg)
        
        div.top
            display: flex
            justify-content: space-between
            align-items: center

        div.progress-bar
            height: 4px
            background: rgba(18, 76, 123, 0.2)
            overflow: hidden

        div.progress
            height: 4px
            background: #124C7B
            transition: 0.1s

    div.inspection-container
        max-width: 560px
        margin: 0 auto

    div.btn-wrap
        display: flex
        justify-content: center
        margin-top: 20px

    div.back-top-btn
        width: 44px
        height: 44px
        display: flex
        justify-content: center
        align-items: center
        background: linear-gradient(0deg, rgba(126, 211, 212, 0.2), rgba(126, 211, 212, 0.2)), #FFFFFF
        border-radius: 50%
        padding: 10px
        cursor: pointer
        img
            width: 100%

    button.save-btn
        max-width: 560px
        margin-top: 0

    div.questions-wrap
        overflow-y: hidden
        transition: 1s
        padding: 0 15px
    
    div.question-type-wrap
        display: flex
        align-items: center
        justify-content: space-between
        margin-top: 20px
        cursor: pointer

        div.arrow
            width: 44px
            height: 44px
            background: linear-gradient(0deg, rgba(126, 211, 212, 0.6), rgba(126, 211, 212, 0.6)), #FFFFFF
            display: flex
            justify-content: center
            align-items: center
            padding: 5px
            cursor: pointer

            img.arrow
                transition: 0.1s
                &.open
                    transform: rotate(-90deg)
                &.close
                    transform: rotate(-180deg)

    h2.question-type
        font-size: 22px
        line-height: 25px
        color: #124C7B
        font-weight: 400
        margin: 0

    div.spinner-wrap
        margin-top: 100px
        display: flex
        justify-content: center
        align-items: center

    p.none-questions-text
        text-align: center
        margin-top: 100px
        font-size: 20px

</style>