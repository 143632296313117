import store from "../store"
import router from "../router"

class Request {
    constructor() {
        this.token = localStorage.getItem("accessToken")
        this.headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.token}`
        }
    }

    async get(url, headers) {
        try {
            let response

            if(headers) {
                for(let key in headers) {
                    this.headers[key] = headers[key]
                }
            }
    
            response = await fetch(url, { method: "GET", headers: this.headers, })
            if(response.status == 403)
                throw new Error("Invalid token")
                
            response = await response.json()

            return response
        } catch (err) {
            if(err.code == 401)
                store.dispatch("auth/signout")
            router.push("/")
            console.error("Catch:", err)
        }
    }

    async post(url, data, headers) {
        try {
            let response

            if(headers) {
                for(let key in headers) {
                    this.headers[key] = headers[key]
                }
            }
    
            response = await fetch(url, {
                method: "POST",
                headers: this.headers,
                body: JSON.stringify(data)
            })
            if(response.status == 403)
                throw new Error("Invalid token")
            response = await response.json()

            return response
        } catch (err) {
            if(err.code == 401)
                store.dispatch("auth/signout")
            router.push("/")
            console.error("Catch:", err)
        }
    }

    async put(url, data, headers) {
        try {
            let response

            if(headers) {
                for(let key in headers) {
                    this.headers[key] = headers[key]
                }
            }
    
            response = await fetch(url, {
                method: "PUT",
                headers: this.headers,
                body: JSON.stringify(data)
            })
            if(response.status == 403)
                throw new Error("Invalid token")
            response = await response.json()

            return response
        } catch (err) {
            if(err.code == 401)
                store.dispatch("auth/signout")
            router.push("/")
            console.error("Catch:", err)
        }
    }

    async delete(url, headers) {
        try {
            let response

            if(headers) {
                for(let key in headers) {
                    this.headers[key] = headers[key]
                }
            }
    
            response = await fetch(url, { method: "DELETE", headers: this.headers, })
            if(response.status == 403)
                throw new Error("Invalid token")
            response = await response.json()

            return response
        } catch (err) {
            if(err.code == 401)
                store.dispatch("auth/signout")
            router.push("/")
            console.error("Catch:", err)
        }
    }
}

export default Request