<template>
    <div class="v-sign-in">
        <div class="sign-in-container">
            <img src="@/assets/sign-in-bg.jpg" alt="title" class="main-img">
            <form class="sign-in-form" @submit.prevent="handleSubmit">
                <h2 class="sign-in-title">Sign in</h2>
                <div class="inp-wrap">
                    <p class="inp-title">Email</p>
                    <input
                        type="email"
                        name="email"
                        class="inp"
                        placeholder="Type email"
                        v-model="email"
                    >
                </div>
                <div class="inp-wrap">
                    <p class="inp-title">Password</p>
                    <input
                        type="password"
                        name="password"
                        class="inp"
                        placeholder="Type password"
                        v-model="password"
                    >
                </div>
                <p class="error" v-show="errorText">{{ errorText }}</p>
                <Button
                    text="sign in"
                    rightImg="door.png"
                    classBtn="sign-in-btn"
                    type="submit"
                    :stateBtn="stateBtn"
                    :isActive="false"
                    :click="() => {}"
                />
            </form>
        </div>
    </div>
</template>

<script>
import Button from "@/components/Button"
import { auth } from "../../api/auth"

export default {
    data: function() {
        return {
            email: "",
            password: "",
            errorText: "",
        }
    },
    components: {
        Button,  
    },
    mounted() {
        this.$store.commit("header/setData", { showLogo: true })
    },
    computed: {
        stateBtn() {
            return this.email.length && this.password.length
        }
    },
    methods: {
        async handleSubmit(e) {
            let data = {}

            ;[...e.currentTarget.elements].forEach(item => {
                if(item.name)
                    data[item.name] = item.value
            })

            try {
                let response = await auth(data)
                
                if (response.success) {
                    await this.$store.dispatch("auth/signin", { email: response.email, accessToken: response.ticket, username: response.username })
                    this.$router.push("/start")
                } else {
                    throw Error("Email or password was entered incorrectly")
                }
            } catch (err) {
                console.error(err)
                this.errorText = err.message
            }
        }
    },
}
</script>

<style lang="sass">
@import ../../helpers/mixins

div.v-sign-in
    padding-bottom: 100px

    img.main-img
        width: 100%

    div.sign-in-container
        max-width: 1000px
        margin: 0 auto

    form.sign-in-form
        max-width: 430px
        margin: 0 auto
        padding: 0 15px

    h2.sign-in-title
        font-weight: 300
        font-size: 40px
        margin-bottom: 20px
        line-height: 64px

    div.inp-wrap
        margin-bottom: 20px
    
    p.inp-title
        font-size: 14px
        line-height: 20px
        color: #434F5B
        margin: 0 0 8px 0

    input.inp
        width: 100%
        font-size: 18px
        padding: 15px 12px

    div.sign-in-btn
        margin-top: 40px

    p.error
        color: red
        font-size: 14px
        margin-bottom: 0
        text-align: center

</style>